import React from "react"
import { Container, Table , Alert} from "react-bootstrap"
import "./style.scss"


export default function Cennik(props) {
  return (
    <>
      <Container className={"text-center"}>

        <h1 className={"text-center pb-5"}>Cennik</h1>

        <Table bordered hover responsive className={"cennikTable"}>
        <thead>
          <tr style={{ whiteSpace: "nowrap" }}>
            <th>ZAJĘCIA GRUPOWE</th>
            <th>1 MIESIĄC</th>
            <th>1 SEMESTR</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>
              45 MIN
            </td>
            <td>140 zł</td>
            <td>630 zł</td>
          </tr>
          <tr>
            <td>
              60 MIN
            </td>
            <td>150 zł</td>
            <td>675 zł</td>
          </tr>
          <tr>
            <td>75 MIN</td>
            <td>160 zł</td>
            <td>720 zł</td>
          </tr>
          <tr>
            <td>90 MIN
            </td>
            <td>170 zł</td>
            <td>765 zł</td>
          </tr>
          <tr>
            <td>Grupy taneczne (2x w tyg.) 60min</td>
            <td>260 zł</td>
            <td>1170 zł</td>
          </tr>
          <tr>
            <td>Grupy taneczne (2x w tyg.) 60min + 90min</td>
            <td>270 zł</td>
            <td>1215 zł</td>
          </tr>
          <tr>
            <td>Grupy taneczne (2x w tyg.) 90min</td>
            <td>280 zł</td>
            <td>1260 zł</td>
          </tr>

          <tr className={""}>
            <td className={"pasek_szary"}>Zajęcia próbne 45, 60 min *</td>
            <td colSpan={2} className={"kreskapomiedz"}>50 zł</td>
          </tr>
          <tr>
            <td>Zajęcia próbne 75, 90 min *</td>
            <td colSpan={2}>65 zł</td>
          </tr>
          <tr>
            <td>Warsztaty taneczne</td>
            <td colSpan={2}>60 zł</td>
          </tr>
          <tr>
            <th>ZAJĘCIA INDYWIDUALNE</th>
            <th>Lekcja 1 h</th>
            <th>Lekcja 1 h<br/>
              <span style={{ fontWeight: "normal" }}>(cena promocyjna)</span>
            </th>
          </tr>
          <tr>
            <td>
              Solo
            </td>
            <td>200 zł</td>
            <td>160 zł</td>
          </tr>

          <tr>
            <td>Duet / Para</td>
            <td>220 zł</td>
            <td>190 zł</td>
          </tr>
          <tr>
            <td>Trio
            </td>
            <td>270 zł</td>
            <td>240 zł</td>
          </tr>
          <tr>
            <th>PIERWSZY TANIEC</th>
            <th colSpan={2}></th>
          </tr>
          <tr>
            <td>
              Pakiet 5 lekcji 60 min
            </td>
            <td colSpan={2}>990 zł</td>
          </tr>
          </tbody>
        </Table>
        <p className={"text-left mb-1"} style={{ fontSize: "0.8rem" }}>* Tylko pierwsze zajęcia dziecka (zajęcia
          próbne),
          płatność kartą lub gotówką na miejscu, istnieje możliwość wliczenia zajęć próbnych do opłaty miesięcznej.
        </p>
        <p className={"text-left mb-1"} style={{ fontSize: "0.8rem" }}>
          Opłata miesięczna w miesiącu obejmującym ferie, z powodu ferii, wynosi 50% całej kwoty.</p>
        <p className={"text-left "} style={{ fontSize: "0.8rem" }}>
          Cena promocyjna lekcji indywidualnych obowiązuje uczestników zajęć grupowych Profil Dance Company.<br/>
        </p>
        <div className={"accountInfo py-4 text-center my-5 rounded"}>
          <p style={{ fontSize: "0.9em" }}>Płatności za zajęcia dokonujemy z góry przed pierwszymi zajęciami w miesiącu
            kartą, gotówką lub przelewem.<br/>
            Płatności za semestr wykonujemy do 10-tego września (1 sem) i 10-tego lutego (2 sem)</p>
          <p style={{ fontSize: "1.2em" }}><b>Profil Dance Ewa Konecka
            <br/>mBank 70 1140 2004 0000 3302 8036 1839</b></p>
          <p><b>Tytuł przelewu: Imię i Nazwisko dziecka, grupa, miesiąc/semestr</b>
            <br/>(przykładowo: Ania Kowalska, AkroGim 1, wrzesień)</p>
        </div>
      </Container>

    </>
  )
}
