import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Cennik from "../components/cennik"

const SecondPage = () => (
  <Layout pageInfo={{ pageName: "cennik" }}>
    <SEO title="Cennik"/>
    <Cennik/>
  </Layout>
)

export default SecondPage
